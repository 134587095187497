import React, { useState } from "react";
import "./login.css";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../config/firebase";
import { useNavigate } from 'react-router-dom';
import { useAppContext } from "../context/AppContext";
import { useEffect } from "react";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState('');
  const navigate = useNavigate(); 
  const {user} = useAppContext();
  useEffect(() => {
    if(user) {
      navigate("/");
    }
  }, [user, navigate])

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await signInWithEmailAndPassword(auth, email, password); // Call Firebase Auth method
      navigate("/");
      
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="container">
      <div className="blurred-box">
        <form onSubmit={handleSubmit}>
          <h2 className="login-title">Login</h2>
          <input
            className="input-field"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <input
            className="input-field"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />

          <button className="login-button" type="submit">
            Login
          </button>
        {error && <span style={{ color: 'red', zIndex: 1 }}>{error}</span>}
        </form>
      </div>
    </div>
  );
};

export default Login;
