import React, {createContext, useContext, useState} from "react";
import { useEffect } from "react";
import { auth } from "../config/firebase";

const AppContext = createContext();

export const AppProvider = ({children}) => {
    const [chatId, setChatId] = useState(null);
    const [user, setUser] = useState(null);
    const [chatList, setChatList] = useState([]);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
      // Set up a listener to track user authentication state
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user) {
          login();
          updateUser(user.email);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
     
      return () => unsubscribe(); // Clean up the listener on unmount
    }, []);
    
    const login = () => {
        setIsAuthenticated(true);
    };

    const logout = () => {
        setIsAuthenticated(false);
        setUser('');
    };
    
    const updateChatId = (id) => {
        setChatId(id);
    }

    const updateUser = (user) => {
        setUser(user);
    }

    const updateChatList = (data) => {
        setChatList(data);
    }

    return (
      <AppContext.Provider
        value={{
          chatId,
          user,
          updateChatId,
          updateUser,
          chatList,
          updateChatList,
          isAuthenticated,
          login,
          logout,
        }}
      >
        {!loading && children}
      </AppContext.Provider>
    );
}

export const useAppContext = () => {
    return useContext(AppContext);
};