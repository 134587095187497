import React, { useEffect } from "react";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  addDoc,
  serverTimestamp,
  doc,
  getDoc,
  deleteDoc,
  where,
} from "firebase/firestore";
import { db, auth } from "../config/firebase";
import wcIcon from "../assets/wisecapital_logo.png";
import { useAppContext } from "../context/AppContext";
import deleteIcon from '../assets/Delete.svg';
import addSquareIcon from '../assets/AddSquare.svg';

const ChatHistory = () => {
  const { user, chatId, updateChatId, chatList, updateChatList, logout } =
    useAppContext();

  useEffect(() => {
    const q = query(
      collection(db, "chats"),
      where("user", "==", user),
      orderBy("time", "desc")
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let chatList = [];
      if (querySnapshot.size === 0) {
        addNewChat();
        return;
      }
      querySnapshot.forEach((doc) => {
        const chatData = { id: doc.id, ...doc.data() };
        if (chatData.messages) {
          chatList.push(chatData);
        }
      });
      updateChatList(chatList);
      updateChatId(chatList[0].id);
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = async (e) => {
    e.preventDefault();
    await auth.signOut();
    await logout();
  };

  const addNewChat = async () => {
    if (chatId) {
      const selectedChatRef = doc(db, "chats", chatId);
      const selectedChatSnap = await getDoc(selectedChatRef);

      if (
        selectedChatSnap.exists() &&
        selectedChatSnap.data().messages.length === 0
      ) {
        // If the current chat has no messages, do not create a new one
        return;
      }
    }
    // Create a new chat only if the current selected chat has messages or no chat is selected
    const newChat = await addDoc(collection(db, "chats"), {
      user: user,
      time: serverTimestamp(),
      messages: [],
    });
    updateChatId(newChat.id);
  };

  const deleteChat = async (chatId) => {
    const chatRef = doc(db, "chats", chatId);
    await deleteDoc(chatRef);
  };

  return (
    <div className="chat-history">
      <div
        style={{
          display: "flex",
          alignItems: 'center',
          justifyContent: "space-between",
          flexDirection: 'row',
          // border: '1px solid black'
        }}
      >
        <h3 style={{marginBottom: 0}}>Chat History</h3>
        {/* <img
          src={wcIcon}
          width={48}
          height={48}
        /> */}
      </div>
      <button onClick={addNewChat} className="new-chat-button">
        New Chat
        <img className="add-icon" src={addSquareIcon} width={24} height={24}/>
      </button>
      <hr style={{ opacity: "0.3" }} />
      <ul>
        {chatList.map((chat) => (
          <li
            key={chat.id}
            onClick={() => updateChatId(chat.id)}
            className="chat-entry"
          >
            <span
              className="chat-entry-text"
              style={{ flexGrow: 1, marginRight: "10px" }}
            >
              {chat.messages[0]?.content ? chat.messages[0]?.content.slice(0, 30) : 'New chat'}
            </span>
            <button style={{background: 'none', border: 'none', cursor: 'pointer'}} onClick={() => deleteChat(chat.id)}><img src={deleteIcon} width={20} height={20}/></button>
            
          </li>
        ))}
      </ul>
      <button type="button" className="logout-button"  onClick={handleLogout}>
        Logout
      </button>
    </div>
  );
};

export default ChatHistory;
