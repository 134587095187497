import React, { useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';


const MessageList = ({ messages }) => {
  const scrollRef = useRef(null); 

  const renderers = {
    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || '');
      return !inline && match ? (
        <SyntaxHighlighter style={atomDark} language={match[1]} PreTag="div" {...props}>
          {String(children).replace(/\n$/, '')}
        </SyntaxHighlighter>
      ) : (
        <code style={{ backgroundColor: '#282c34', padding: '2px 4px', borderRadius: '3px', color: '#f8f8f2' }} {...props}>
          {children}
        </code>
      );
    },
  };

    useEffect(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    }, [messages]); // Dependency array includes messages

  return (
    <div className="messages" 
    ref={scrollRef}
    >
      {messages.map((msg, index) => {
        const formattedMessage = msg.role === 'user' ? msg.content
        .replace(/^\s+/gm, '    ')
        .replace(/\n/g, '<br />') : msg.content;
        return (
          <div
            key={index}
            className={`message ${msg.role === 'user' ? 'user' : 'bot'}`}
          >
           {msg.role === 'user' ? (
              <div dangerouslySetInnerHTML={{ __html: formattedMessage }}/> 
            ) : (
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={renderers}
              >
                {formattedMessage}
              </ReactMarkdown> 
            )}
          </div>
        )
      })}
    </div>
  );
};

export default MessageList;
