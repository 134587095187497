import React, { useState, useEffect } from 'react';
import {
  doc,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import { db, auth } from '../config/firebase';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import { useAppContext } from '../context/AppContext';
import { fetchGptResponse } from '../services/apiService';

const ChatWindow = (
  // { chatId, user}
) => {
  const {chatId, chatList} = useAppContext();
  const [currentChat, setCurrentChat] = useState(null); // Store the current chat document reference
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    setLoading(false);
    const chat = chatList.find(item => item.id === chatId);
    setCurrentChat(chat);
  }, [chatId])

  // useEffect(() => {
  //   setLoading(false);
  //   if (chatId) {
  //     // Query to fetch the selected chat document
  //     const chatRef = doc(db, 'chats', chatId);
  //     const unsubscribe = onSnapshot(chatRef, (docSnapshot) => {
  //       if (docSnapshot.exists()) {
  //         setCurrentChat({ id: docSnapshot.id, ...docSnapshot.data() });
  //       } else {
  //         // Handle the case where the chat doesn't exist
  //       }
  //     });
  //     return () => unsubscribe();
  //   } else {
  //   }
  // }, [chatId]);

  const sendMessage = async (text) => {
    if (text.trim() && currentChat) {
      const newMessageFromUser = {
        role: 'user',
        content: text,
      };
      const chatRef = doc(db, 'chats', currentChat.id);

      // Update the chat document with the new message
      const updatedMessageFromUser = [...currentChat.messages, newMessageFromUser];
      setCurrentChat({...currentChat, messages: updatedMessageFromUser});

      setLoading(true);
      const messagesArray = updatedMessageFromUser;
      
      // Fetch GPT response
      const token = await auth.currentUser.getIdToken();
      
      const gptResponse = await fetchGptResponse(messagesArray, token);

      const gptMessage = {
        role: 'assistant',
        content: gptResponse,
      };

      const finalUpdatedMessage = [...updatedMessageFromUser, gptMessage];
      setCurrentChat({...currentChat, messages: finalUpdatedMessage})
      // Update the chat document with the GPT response
      await updateDoc(chatRef, {
        messages: finalUpdatedMessage,
        time: serverTimestamp(),
      });

      setLoading(false);
    }
  };

  return (
    <div className="chat-window">
      <MessageList messages={currentChat ? currentChat.messages : []} />
      {loading && <div className="loading-text">Loading...</div>} 
      <MessageInput sendMessage={sendMessage} />
    </div>
  );
};

export default ChatWindow;
