import axios from "axios";

// Set the base URL for Axios
const apiClient = axios.create({
  baseURL: 'https://gpt.wisecapital.com.vn/api/',
  headers: {
    'Content-Type': 'application/json'
  }
});

export const fetchGptResponse = async (messages, token) => {
    try {
      const response = await apiClient.post('/messages', { messages }, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error sending messages:', error.response || error.message);
      throw error;
    }
  };
  