import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import EnterIcon from '../assets/enter-icon.svg';
const MessageInput = ({ sendMessage }) => {
  const [input, setInput] = useState("");
  const textareaRef = useRef(null);

  useEffect(() => {
    // Adjust the height of the textarea based on the scrollHeight
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Temporary set to auto to measure height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [input]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevents the default behavior of Enter key
      handleSubmit(e); // Call the submit function
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim()) {
      sendMessage(input);
      setInput(""); // Clear input after sending
    }
  };

  return (
    <div className="input-form-container">
      <form onSubmit={handleSubmit} className="input-form">
        <textarea
          ref={textareaRef}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Type your message..."
          className="message-input"
          // rows="2"
        />
          <button
            type="submit"
            className="send-button"
          >
            <img src={EnterIcon} width={24} height={24}/>
          </button>
      </form>
    </div>
  );
};

export default MessageInput;
