import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyB_lMFrJKPI98rm6YIKx-Ov_FmXqTBiEu4",
    authDomain: "chat-gpt-clone-7cf2c.firebaseapp.com",
    projectId: "chat-gpt-clone-7cf2c",
    storageBucket: "chat-gpt-clone-7cf2c.appspot.com",
    messagingSenderId: "342993547824",
    appId: "1:342993547824:web:88cf3b2562ba100cdbf3f5",
    measurementId: "G-SJYYF185KS"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
export { db, auth };
