import React from "react";
import ChatHistory from "../component/ChatHistory";
import ChatWindow from "../component/ChatWindow";

const Home = () => {
  return (
    <div className="app-container">
      <ChatHistory />
      <ChatWindow />
    </div>
  );
};

export default Home;